import React from 'react'
import { Link } from '~components'
import { Col, Container, Row } from "react-bootstrap";
import ErrorPage from "./style";
import { Images } from '~data';


export default function Error(){
return(
<ErrorPage backgroundColor="#f3f4f6">
  <Container>
    <Row className="justify-content-center">
      <Col xs="12" className="col-xl-6 col-lg-7 col-md-8 col-xs-10">
        {/* <ErrorPage.Image className="error-page__image text-center">
          <img className="w-100" src={Images.errorImage} alt />
        </ErrorPage.Image> */}
        
          <ErrorPage.BigTitle as="h1"></ErrorPage.BigTitle>
          
          <ErrorPage.Text>We recently revamped our website.The page you are looking for might have been moved to a different location</ErrorPage.Text>
          
          <ErrorPage.ButtonGroup>
          <ErrorPage.Button className="btn-readical-red btn-1" as={Link} to="/">Go to Home</ErrorPage.Button>
          <ErrorPage.Button className="btn-outline-readical-red btn-2" as={Link} to="/contact-us">Contact us</ErrorPage.Button>
          <p><br></br></p>
        </ErrorPage.ButtonGroup>
         
        
      </Col>
    </Row>
  </Container>
</ErrorPage>

)
}