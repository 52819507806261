import React from "react";
import { Error } from "~sections/utility";
import { PageWrapper } from "~components/Core";
import FooterFive from '~sections/agency/FooterFive';
import BreadCrumbSection from "~sections/about/BreadCrumb";

const header = {
  headerClasses: "site-header site-header--menu-end dark-header site-header--sticky",
  containerFluid:false,
  darkLogo:false,
  // buttonBlock: (
  //   <HeaderButton
  //     className="ms-auto d-none d-xs-inline-flex"
  //     btnText="Appointments"
  //     mr="15px"
  //     mrLG="0"
  //   />
  // ),
}

export default function errorPage() {
  return (
    <PageWrapper headerConfig={header}>
      <BreadCrumbSection title="Page Missing" text=""/>
        <Error/>
        <FooterFive/>
    </PageWrapper>
  )
}
